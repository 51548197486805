<template>
  <div class="col-12" v-html="html"></div>
</template>

<script>
export default {
  props: {
    paginas: {
      type: Array,
    },
    rota: {
      type: String,
    },
  },
  data() {
    return {
      html: '',
      titulo: '',
      pagina: '',
    };
  },
  methods: {
    getPagina() {
      this.pagina = this.paginas.find((resp) => resp.rota == this.rota);
      this.html = this.pagina.html;
      this.titulo = this.pagina.titulo;
    },
  },
  async mounted() {
    this.getPagina();
  },
};
</script>

<style scoped>
img {
  width: 100%;
}
</style>
